@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");


/* team dropdown */



/* chart page tooltip design */
.recharts-tooltip-wrapper {
  width: 90%;

}

.recharts-default-tooltip {
  white-space: normal !important;

}

.recharts-tooltip-label {}

/* fonts */
.poppins {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

/* modal */
.modal-content {
  border-radius: 20px;
  /* width: 450px; */
}

/* data table */
.sorting {
  background-color: #f3f3ff !important;
  margin-top: 30px !important;
}

.table> :not(caption)>*>* {
  padding: 20px;
}

.table.table-hover.dataTable {
  margin-top: 20px !important;
}

.col-sm-12.col-md-5 {
  display: none;
}

.col-sm-12.col-md-7 {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  width: 100%;
}

.active.page-item .page-link {
  color: rgb(255, 255, 255) !important;
  background-color: black;
}

.page-item .page-link {
  color: black !important;
}

.pagination {
  gap: 10px;
}

.form-control.form-control-sm.ml-0.my-1 {
  border-radius: 5px;
  padding: 10px;
  width: 250px;
}

/* loader */
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color: white;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.my-tooltip {
  z-index: 5555;
  left: 0;
}

.triangle_down11 {
  position: relative;
  top: -5px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1em;
}

.triangle_down1 {
  position: relative;
  top: -42px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 15px;
  margin-left: 15px;
}

.triangle_down2 {
  position: relative;
  top: 0px;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(47deg);
  margin-right: 15px;
  margin-left: 0px;
}

.accordion-button:not(.collapsed) {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.col-sm-12 {
  margin-top: 7px;
}

label.sc-KXCwU.fsWyvy {
  height: 100px;
  margin: auto;
}

.custom-fileUploader {
  height: 100px !important;
  margin: auto;
}

a.page-link.page-link {
  z-index: auto;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}